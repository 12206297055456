<template>
 <div id="application">
    <component :is="header" :visible-section="visibleSection" />
    <div class="section" id="hero">
        <component :is="hero" />
    </div>
    <Introduction class="section" id="introduction" />
    <div class="section" id="experience">
        <component :is="experience" />
    </div>
    <Seminars class="section" id="seminars_certifications" />
    <Footer class="section" id="contact" />
 </div>
</template>

<script setup>
import Seminars from '@/sections/Seminars/Seminars.vue';
import Introduction from '@/sections/Introduction/Introduction.vue';
import Footer from '@/sections/Footer/Footer.vue';
import Header from '@/sections/Header/Header.vue';
import HeaderMobile from '@/sections/Header/HeaderMobile.vue';
import Hero from '@/sections/Hero/Hero.vue';
import HeroMobile from '@/sections/Hero/HeroMobile.vue';
import Experience from '@/sections/Experience/Experience.vue';
import ExperienceMobile from '@/sections/Experience/ExperienceMobile.vue';
import  { isSmallScreen } from '@/helpers/isSmallScreen.js';
import { event } from 'vue-gtag';

import { onMounted, ref, computed } from 'vue';

const header = computed( () => (isSmallScreen.value) ?
    HeaderMobile : 
    Header
);

const hero = computed( () => (isSmallScreen.value) ?
   HeroMobile : 
   Hero
);

const experience = computed( () => (isSmallScreen.value) ?
    ExperienceMobile : 
    Experience
);

const visibleSection = ref(null);

event('pageVisit', { 
    'event_category': "Initial",
    'event_label': "Visited Page",
    'value': 1
 })

onMounted( () => {
    const observerOptions = {
        root: null,
        threshold: .2,
    };

    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                visibleSection.value = entry.target.getAttribute('id');
            }
        });
    }, observerOptions);

    const sections = document.getElementsByClassName('section');

    for (let section of sections) {
        observer.observe(section);
    }
} );

</script>
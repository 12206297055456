import { createApp } from 'vue'
import App from './App.vue'
import VueGtag from "vue-gtag-next";

import VueParticles from 'vue-particles'

const app = createApp(App);

app.use(VueParticles);
app.use(VueGtag, {
    property: {
        id: "GTM-WPDWM6P",
    },
    }
);

app.mount('#app');

<template>
    <li :class="style.seminarsListItem">
        <h3 class="h4">{{ seminar.title }}</h3>    
        <small>{{ seminar.company }}</small>    
        <time>{{ seminar.startDate }} - {{ seminar.endDate }}</time>
        <a aria-label="Seminar Credential Download" v-if="seminar.credential" :href="seminar.credential">Credential
            <svg :class="style.svg" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                y="0px" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
                <polyline points="12.25,5 23.25,16 12.25,27 " />
            </svg>
        </a>
        <div v-if="skills && seminar.skills.list.length" >
            <span :class="style.skillsTitle">{{ seminar.skills.title }}: </span>
            <span :class="style.skillsContent">{{ skills }}</span>
        </div>
    </li>
</template>
<script setup>
import { defineProps } from 'vue';
import style from './SeminarsListItem.module.scss';

const props = defineProps({
    seminar: {
        type: Object,
        required: true
    }
});

const skills = props.seminar?.skills?.list.map( (item) => item.content ).join(', ');


</script>
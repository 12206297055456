<template>
    <div>
        <HeroContentMobile :content="content"/>
    </div>
</template>

<script>
export default {
    name: 'Hero'
};
</script>

<script setup>
import HeroContentMobile from '@/components/Hero/HeroContentMobile.vue';
import content from './content.js';
</script>
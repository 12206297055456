<template>
    <div :class="[style.burgerIcon, { [style.scrolled] : scrolled} ]" @click="handleClick" >
        <span :class="style.burgerLine"></span>
        <span :class="style.burgerLine"></span>
        <span :class="style.burgerLine"></span>
    </div>
</template>

<script setup>
import style from './Burger.module.scss';
import { defineEmits, defineProps, getCurrentInstance } from 'vue';

defineEmits('clicked');
const instance = getCurrentInstance().proxy;

defineProps({
    scrolled: {
        type: Boolean,
        required: true
    }
});

const handleClick = () => {
    instance.$emit('clicked');
}
</script>

<template>
    <div>
        <HeroParticles />
        <HeroContent :content="content"/>
    </div>
</template>

<script>
export default {
    name: 'Hero'
};
</script>

<script setup>
import HeroContent from '@/components/Hero/HeroContent.vue';
import HeroParticles from '@/components/Hero/HeroParticles.vue';
import content from './content.js';
</script>
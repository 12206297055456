export const experience = [
    {
        "title": "Senior Front-End Engineer",
        "company": "Spotawheel",
        "startDate": "05/2022",
        "endDate": "Currently",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "VueJS"
                },
                {
                    "content": "Laravel"
                },
                {
                    "content": "REST APIs"
                },
                {
                    "content": "Vanilla Javascript"
                },
                {
                    "content": "SCSS"
                },
                {
                    "content": "HTML5 best practices"
                },
                {
                    "content": "Accessibility & SEO best practices"
                },
            ]
        }
    },
    {
        "title": "Senior Front-End Engineer",
        "company": "Empist",
        "startDate": "12/2018",
        "endDate": "04/2022",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "VueJS"
                },
                {
                    "content": "Shopify & Wordpress (Custom Theming)"
                },
                {
                    "content": "REST APIs"
                },
                {
                    "content": "Vanilla Javascript"
                },
                {
                    "content": "SCSS"
                },
                {
                    "content": "HTML5 best practices"
                },
                {
                    "content": "Accessibility & SEO best practices"
                },
            ]
        }
    },
    {
        "title": "Mid/Senior Front-End Engineer",
        "company": "ClickMedia",
        "startDate": "10/2016",
        "endDate": "11/2018",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "jQuery"
                },
                {
                    "content": "Wordpress (Custom Theming)"
                },
                {
                    "content": "Joomla (Custom Theming)"
                },
                {
                    "content": "Vanilla Javascript"
                },
                {
                    "content": "CSS3 & CSS Animations"
                },
            ]
        }
    },
    {
        "title" : "Front-End Engineer",
        "company": "Freelance",
        "startDate": "04/2016",
        "endDate": "10/2016",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "Joomla"
                },
                {
                    "content": "PHP"
                },
                {
                    "content": "SCSS"
                },
                {
                    "content": "jQuery"
                },
                {
                    "content": "Javascript"
                },
                {
                    "content": "MySQL"
                },
            ]
        }
    },
    {
        "title": "Junior Front-End Developer",
        "company": "iMode",
        "startDate": "10/2015",
        "endDate": "04/2016",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "jQuery"
                },
                {
                    "content": "Joomla (Custom Theming)"
                },
                {
                    "content": "Email Templating using Mainchimp"
                },
                {
                    "content": "Training customers"
                }
            ]
        }
    },
    {
        "title": "Entry-level Front-End Developer",
        "company": "Netikon",
        "startDate": "10/2014",
        "endDate": "06/2015",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "jQuery"
                },
                {
                    "content": "Joomla (Custom Theming)"
                },
                {
                    "content": "Site maintenance"
                },
                {
                    "content": "Custom HTML sites"
                }
            ]
        }
    },
    {
        "title": "Private Lessons (German Language & AEP)",
        "company": "Freelance",
        "startDate": "09/2013",
        "endDate": "06/2014",
        "skills": {
            "title": "Skills",
            "list": [
                {
                    "content": "I was teaching AEP and German Lessons to HighSchool and Lyceum children."
                }
            ]
        }
    }
];
<template>
    <div :class="style.experience">
        <Container :class="style.experienceContainer">
            <ExperienceTimeline :active-skill-index="data.activeSkillIndex" :experiences="experience" @clicked="getSkills" />
            <InfoNote :class="style.infoNote" :content="data.skills" />
        </Container>
    </div>
</template>

<script setup>
import { experience } from "./content.js";
import style from './Experience.module.scss';
import InfoNote from '@/components/InfoNote/InfoNote.vue';
import Container from '@/components/Container/Container.vue';
import ExperienceTimeline from '@/components/ExperienceTimeline/ExperienceTimeline.vue';
import { reactive } from 'vue';

const data = reactive({
    skills:  experience[0].skills,
    activeSkillIndex: 0
});

const getSkills = (skills, activeSkillIndex) => {
    data.skills = skills;
    data.activeSkillIndex = activeSkillIndex;
} 
</script>
export const intro = {
    "title": "Introduction",
    "content": "<p>Hello there!</p><p>I am <strong>Christine Kefalianou</strong> and I’m working as a <strong>Senior Front-End  Enginner</strong>. I’ve graduated from A.T.E.I. of Central Greece, as an Informatics Engineer.</p><p>The reason I chose to be an FE Engineer is because I believe it is really interesting and creative.</p> <p>As a character, I am <strong>communicative</strong>, I have <strong>organizational skills</strong> and I appreciate the <strong>value of teamwork</strong>.</p>",
};

export const details = {
    "title" : "a little more about me....",
    "list": [
        {
            "title": "Bachelor:",
            "content": "Informatics Engineering A.T.E.I. of Central Greece",
            "degree": "Diploma Degree: 7.43"
        },
        {
            "title": "Thesis:",
            "content": "Image Recognition & Classification using SVM",
            "degree": "Thesis Degree: 10"
        },
        {
            "title": "Languages:",
            "content": "English: Pearson PTE-LCCI (C2)</br>German: Goethe-Zertifikat (B1)"
        },
        {
            "title": "Driving Licence"
        }
    ]
};